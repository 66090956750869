import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Sustainable Synergy' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Collaboration can amplify our efforts toward 
        a sustainable future. Whether it's AI or 
        supply chain management, 
        let's collaborate for a better tomorrow.


      </Text>
    </Card>
  </Section>
)

export default Commitment
